import React, { createContext, useState, useContext, useCallback, useLayoutEffect } from 'react';
import { Box } from "@quarkly/widgets";
import { useApiContext } from "./DirectualApiProvider";
const authContext = createContext({});
/**
 * @params { Object } directualAPI - Instance DirectualAPI class from directual-api
 */

const useUser = directualAPI => {
	const [user, setUser] = useState();
	useLayoutEffect(() => {
		if (!directualAPI) {
			return;
		}

		let user;

		try {
			user = JSON.parse(window.localStorage.getItem("qud"));
		} catch (_) {
			user = undefined;
		}

		if (user) {
			setUser(user);
		}
	}, []);
	const login = useCallback(async (username, password) => {
		if (!directualAPI) {
			return;
		}

		const result = await directualAPI.auth.login(username, password);
		const userData = {
			username: result.username,
			role: result.role,
			sessionID: result.sessionID
		};
		setUser(userData);
		window.localStorage.setItem("qud", JSON.stringify(userData));
	}, [directualAPI]);
	const signout = useCallback(() => {
		setUser(undefined);
		window.localStorage.setItem('qud', undefined);
	}, [user]);
	const isRole = useCallback(role => {
		if (!user) {
			return;
		}

		if (!role) {
			return true;
		}

		return user.role === role;
	}, [user]);

	if (!directualAPI) {
		return {};
	}

	return {
		user,
		login,
		signout,
		isAuthorized: Boolean(user),
		isRole
	};
};

const DirectualAuthProvider = ({
	children,
	...otherProps
}) => {
	const api = useApiContext();
	const user = useUser(api);
	return <authContext.Provider value={user}>
		      
		<Box {...otherProps}>
			          
			{children}
			      
		</Box>
		    
	</authContext.Provider>;
};

export const useAuthContext = () => useContext(authContext);
Object.assign(DirectualAuthProvider, {
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "Directual Auth Provider"
	},
	propInfo: { ...Box.propInfo
	}
});
export default DirectualAuthProvider;