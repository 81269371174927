import React, { useState, useCallback } from "react";
import atomize from "@quarkly/atomize";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Input, Button, Section, LinkBox } from "@quarkly/widgets";
import { useApiContext } from "./DirectualApiProvider";
const defaultProps = {
	"width": "100wv",
	"min-width": "100wv",
	"position": "sticky",
	"top": "160px",
	"z-index": "4",
	"sm-padding": "4px 8px 0px 8px",
	"sm-overflow-y": "visible",
	"sm-overflow-x": "visible",
	"quarkly-title": "LastForm",
	"margin": "0 0 0px 0",
	"sm-position": "static",
	"sm-background": "--color-dark",
	"height": "calc(100vh - 160px)",
	"min-height": "calc(100vh - 160px)",
	"background": "rgba(24, 25, 31, 0)",
	"padding": "0px 32px 16px 32px"
};
const overrides = {
	"section": {
		"kind": "Section",
		"props": {
			"width": "100%",
			"min-width": "100%",
			"background": "--color-orange",
			"z-index": "4",
			"sm-padding": "24px 8px 8px 8px",
			"top": "160px",
			"quarkly-title": "lastForm",
			"border-radius": "20px 20px 20px 20px",
			"sm-margin": "0 0 0px 0",
			"sm-border-radius": "15px 15px 15px 15px",
			"min-height": "100%",
			"height": "100%",
			"position": "static",
			"padding": "0px 0 0px 0",
			"margin": "0 0 0 0"
		}
	},
	"sectionOverride": {
		"kind": "Override",
		"props": {
			"slot": "SectionContent",
			"width": "100%",
			"height": "100%",
			"min-height": "100%",
			"min-width": "100%",
			"color": "--dark",
			"justify-content": "center",
			"md-justify-content": "flex-start",
			"sm-position": "static",
			"flex-direction": "row",
			"align-items": "center",
			"sm-flex-direction": "column"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "max-content",
			"display": "flex",
			"margin": "0px 0px 0px 10px",
			"md-flex-direction": "column",
			"quarkly-title": "Box",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-margin": "0px 0px 0px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "max-content",
			"display": "flex",
			"margin": "0px 0px 60px 0px",
			"md-flex-direction": "column",
			"quarkly-title": "headlineBox",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-min-height": "max-content",
			"align-items": "center",
			"sm-margin": "0px 0px 40px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"width": "100px",
			"min-width": "100%",
			"sm-font": "--text",
			"md-font": "--headline3Mob",
			"height": "max-content",
			"min-height": "max-content",
			"quarkly-title": "headline",
			"display": "flex",
			"align-items": "center",
			"text-align": "center",
			"justify-content": "center",
			"children": <>
				Заказать{" "}
				<br />
				проект мечты!
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"height": "max-content",
			"display": "flex",
			"margin": "0px 0px 0px 0px",
			"md-flex-direction": "column",
			"quarkly-title": "contactsBox",
			"flex-direction": "row",
			"justify-content": "center",
			"sm-min-height": "max-content"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"min-width": "50%",
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"flex-direction": "column",
			"sm-width": "100%",
			"sm-min-width": "0px",
			"quarkly-title": "FORM",
			"align-items": "flex-start",
			"md-width": "80%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"min-width": "100%",
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"quarkly-title": "name"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--light",
			"margin": "00px 0px 0px 0px",
			"width": "max-content",
			"min-width": "max-content",
			"sm-font": "normal 400 16px/1.5 --fontFamily-googleMontserrat",
			"height": "max-content",
			"min-height": "max-content",
			"md-font": "--lead",
			"sm-width": "max-content",
			"sm-min-width": "max-content",
			"md-margin": "0px 0px 0px 0px",
			"quarkly-title": "nameText",
			"children": "Ваше Имя"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"color": "--light",
			"border-width": "0 0 3px 0",
			"border-style": "solid",
			"border-color": "--color-light",
			"height": "25px",
			"placeholder-color": "rgba(255, 255, 255, 0.53)",
			"margin": "0 0 0 8px",
			"width": "279px",
			"padding": "0 0 0 4px",
			"md-width": "244px",
			"border-radius": "0px",
			"sm-width": "calc(100% - 94px)",
			"quarkly-title": "nameInput",
			"background": "--color-orange",
			"type": "text",
			"name": "name",
			"placeholder": "Виктор",
			"sm-color": "--light"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"min-width": "100%",
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"margin": "12px 0px 0px 0px",
			"border-radius": "0px",
			"quarkly-title": "tel"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--light",
			"margin": "00px 0px 0px 0px",
			"width": "max-content",
			"min-width": "max-content",
			"sm-font": "normal 400 16px/1.5 --fontFamily-googleMontserrat",
			"height": "max-content",
			"min-height": "max-content",
			"md-font": "--lead",
			"sm-width": "max-content",
			"sm-min-width": "max-content",
			"md-margin": "0px 0px 0px 0px",
			"quarkly-title": "telText",
			"children": "Ваш телефон"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"color": "--light",
			"border-width": "0 0 3px 0",
			"border-style": "solid",
			"border-color": "--color-light",
			"height": "25px",
			"type": "tel",
			"placeholder": "+7 (495) 968-65-00",
			"placeholder-color": "rgba(255, 255, 255, 0.53)",
			"width": "250px",
			"margin": "0 0 0 8px",
			"padding": "0 0 0 4px",
			"md-width": "214px",
			"border-radius": "0px",
			"sm-width": "calc(100% - 119px)",
			"quarkly-title": "telInput",
			"background": "--color-orange",
			"name": "tel",
			"sm-color": "--light"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"min-width": "100%",
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"margin": "12px 0px 0px 0px",
			"border-radius": "0px",
			"quarkly-title": "mail"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--light",
			"margin": "00px 0px 0px 0px",
			"width": "max-content",
			"min-width": "max-content",
			"sm-font": "normal 400 16px/1.5 --fontFamily-googleMontserrat",
			"height": "max-content",
			"min-height": "max-content",
			"md-font": "--lead",
			"sm-width": "max-content",
			"sm-min-width": "max-content",
			"quarkly-title": "mailText",
			"children": "Ваш email"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"color": "--light",
			"border-width": "0 0 3px 0",
			"border-style": "solid",
			"border-color": "--color-light",
			"height": "25px",
			"type": "email",
			"placeholder": "hello@code-word.ru",
			"placeholder-color": "rgba(255, 255, 255, 0.53)",
			"width": "282px",
			"margin": "0 0 0 8px",
			"padding": "0 0 0 4px",
			"md-width": "246px",
			"border-radius": "0px",
			"sm-width": "calc(100% - 93px)",
			"quarkly-title": "mailInput",
			"background": "--color-orange",
			"name": "mail",
			"sm-color": "--light"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "24px 0px 0px 0px",
			"width": "320px",
			"hover-background": "--color-dark",
			"background": "--color-dark",
			"sm-width": "100%",
			"children": "Получить консультацию"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"align-items": "flex-start",
			"flex-direction": "column",
			"md-margin": "30px 0px 0px 0px",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"quarkly-title": "contacts",
			"min-width": "50%"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--menu",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"width": "max-content",
			"min-width": "max-content",
			"sm-font": "--leadMob",
			"height": "max-content",
			"min-height": "max-content",
			"md-font": "--menu",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"md-margin": "10px 0px 0px 0px",
			"quarkly-title": "telmailCDWRD",
			"sm-text-decoration-line": "underline",
			"children": "+7 (495) 968-65-00"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"font": "--menu",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"width": "max-content",
			"min-width": "max-content",
			"sm-font": "--leadMob",
			"height": "max-content",
			"min-height": "max-content",
			"md-font": "--menu",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"md-margin": "10px 0px 0px 0px",
			"quarkly-title": "mailCDWRD",
			"sm-text-decoration-line": "underline",
			"children": "hello@code-word.ru"
		}
	},
	"SectionContent": {
		"props": {
			"height": "100%",
			"min-height": "100%",
			"max-height": "100%"
		}
	}
};
const Form = atomize.form();

const usePayCallback = (name, phone, email) => {
	const [response, setResponse] = useState();
	return [response, useCallback(() => {
		const data = {
			'name': name,
			'phone': phone,
			'email': email
		};
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		};

		try {
			const res = fetch('https://api.directual.com/good/api/v5/data/request/requestFromSite?appID=cf533faa-d52a-4193-8e5d-358b15570726&sessionID=', requestOptions);
			setResponse(res);
			console.log(res);
		} catch (err) {
			console.log(err);
		}
	}, [name, phone, email])];
};

const LastForm = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [response, handleClick] = usePayCallback(name, phone, email);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			height="100%"
			min-height="100%"
			min-width="100%"
			justify-content="flex-start"
			flex-direction="row"
			sm-flex-direction="column"
			sm-overflow-y="hidden"
		/>
		<Section {...override("section")}>
			<Override {...override("sectionOverride")} />
			<Box {...override("box")}>
				<Box {...override("box1")}>
					<Text {...override("text")} />
				</Box>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Box {...override("box4")}>
							<Text {...override("text1")} />
							<Input {...override("input")} onChange={e => setName(e.target.value)} />
						</Box>
						<Box {...override("box5")}>
							<Text {...override("text2")} />
							<Input {...override("input1")} onChange={e => setPhone(e.target.value)} />
						</Box>
						<Box {...override("box6")}>
							<Text {...override("text3")} />
							<Input {...override("input2")} onChange={e => setEmail(e.target.value)} />
						</Box>
						<Button {...override("button")} onClick={handleClick} />
					</Box>
					<Box {...override("box7")}>
						  
						<LinkBox {...override("linkBox0")}>
							      
							<Text {...override("text4")} />
							      
						</LinkBox>
						  
						<LinkBox {...override("linkBox1")}>
							<Text {...override("text5")} />
							      
						</LinkBox>
					</Box>
				</Box>
			</Box>
		</Section>
		{children}
	</Section>;
};

Object.assign(LastForm, { ...Section,
	defaultProps,
	overrides
});
export default LastForm;