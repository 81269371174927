import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { IoIosMenu, IoMdMenu } from "react-icons/io";
const defaultProps = {
	"sm-position": "sticky",
	"sm-top": "7px",
	"sm-z-index": "120",
	"animDuration": "0.5s",
	"breakpoint": "all",
	"z-index": "90",
	"top": "11px",
	"position": "sticky",
	"menuPosition": "full",
	"sm-margin": "-34px 0px 0px 0px",
	"margin": "-38px 0px 0px 0px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"height": "100%",
			"min-height": "100%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"sm-width": "100%",
			"sm-min-width": "100%",
			"margin": "0px 0px 40px 0px",
			"height": "max-content",
			"sm-margin": "0px 0px 20px 0px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"sm-width": "100%",
			"sm-min-width": "100%",
			"margin": "0px 0px 60px 0px",
			"height": "170px",
			"sm-margin": "0px 0px 20px 0px",
			"quarkly-title": "Logo"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"width": "100%",
			"height": "170px",
			"sm-width": "100%",
			"sm-display": "inline-block",
			"sm-height": "124px",
			"src": "https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/00-logo.svg?v=2021-08-06T06:07:23.438Z",
			"object-position": "0%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"height": "max-content",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "flex-start"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 11px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"quarkly-title": "agencyBox"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"sm-color": "--light",
			"sm-font": "--text",
			"font": "--headline3",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"quarkly-title": "agencyText",
			"children": "Агентство"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 11px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"quarkly-title": "servicesBox"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"sm-color": "--light",
			"sm-font": "--text",
			"font": "--headline3",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"quarkly-title": "servicesText",
			"children": "Услуги"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 11px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"quarkly-title": "projectsBox"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"sm-color": "--light",
			"sm-font": "--text",
			"font": "--headline3",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"quarkly-title": "projectsText",
			"children": "Проекты"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 11px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"quarkly-title": "articlesBox"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"sm-color": "--light",
			"sm-font": "--text",
			"font": "--headline3",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"quarkly-title": "articlesText",
			"children": "Полезные материалы"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 11px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"quarkly-title": "articlesBox"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"sm-color": "--light",
			"sm-font": "--text",
			"font": "--headline3",
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"quarkly-title": "articlesText",
			"children": "Вакансии"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"height": "120px",
			"display": "flex",
			"align-items": "flex-start",
			"justify-content": "flex-start",
			"sm-height": "100px",
			"flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"sm-font": "--text",
			"font": "--headline3",
			"color": "#ed5d40",
			"children": "Контакты"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"height": "m",
			"display": "flex",
			"align-items": "center",
			"justify-content": "flex-start",
			"sm-height": "100px",
			"width": "max-content"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"width": "max-content",
			"height": "34px",
			"src": "https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z",
			"margin": "0px 12px 0px 0px"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"width": "34px",
			"height": "34px",
			"src": "https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z",
			"margin": "0px 12px 0px 0px"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"width": "34px",
			"height": "34px",
			"src": "https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/iconfinder-icon.svg?v=2021-08-06T09:23:38.590Z",
			"margin": "0px 12px 0px 0px"
		}
	}
};

const MenuSiteTop = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitMobileSidePanel {...rest}>
		<Override
			slot="Button Text"
			sm-width="16px"
			sm-height="16px"
			sm-min-width="16px"
			sm-min-height="16px"
		/>
		<Override
			slot="Content :open"
			sm-width="94vw"
			sm-top="0px"
			sm-height="100vh"
			width="75vw"
			min-width="75vw"
			height="100vh"
			border-color="--color-dark"
			hover-width="75vw"
			hover-height="100vh"
			max-width="75vw"
			left="25vw"
			bottom="0px"
			padding="20px 16px 20px 16px"
			sm-left="6vw"
			sm-max-width="94vw"
			sm-min-width="94vw"
		/>
		<Override slot="Button Text :closed">
			{" "}
		</Override>
		<Override
			slot="Content"
			sm-height="100vh"
			sm-width="10vw"
			border-color="--color-dark"
			width="10vw"
			height="100vh"
			background="--color-dark"
			left="100vw"
		/>
		<Override slot="Button Icon :open" category="io" icon={IoIosMenu} />
		<Override
			slot="Button Icon"
			sm-margin="0px 10px 0px 0px"
			category="io"
			icon={IoMdMenu}
			margin="-4px 16px 0px 0px"
			color="--orange"
			size="30px"
		/>
		<Override slot="Button Icon :closed" icon={IoMdMenu} category="io" color="--dark" />
		<Override slot="Children" width="100%" />
		<Override slot="Overlay" position="sticky" />
		<Override slot="Cross" color="--orange" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Image {...override("image")} />
				</Box>
				<Box {...override("box3")}>
					<Box {...override("box4")}>
						<Text {...override("text")} />
					</Box>
					<Box {...override("box5")}>
						<Text {...override("text1")} />
					</Box>
					<Box {...override("box6")}>
						<Text {...override("text2")} />
					</Box>
					<Box {...override("box7")}>
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box8")}>
						<Text {...override("text4")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box9")}>
				<Text {...override("text5")} />
				<Box {...override("box10")}>
					<Image {...override("image1")} />
					<Image {...override("image2")} />
					<Image {...override("image3")} />
				</Box>
			</Box>
		</Box>
		{children}
	</QuarklycommunityKitMobileSidePanel>;
};

Object.assign(MenuSiteTop, { ...QuarklycommunityKitMobileSidePanel,
	defaultProps,
	overrides
});
export default MenuSiteTop;