import React from 'react';
import { Box } from "@quarkly/widgets";
import { useOverrides } from "@quarkly/components";
import { useAuthContext } from "./AuthProvider";
const overrides = {};
/***
 * 
 * const PrivateLink = (rest) => {
 *  const { isAuthorized, user } = useAuthContext();
 * 
 *  return <Link
 *    {...rest}
 *    {...{
 *      [isAuthorized && user.role === "admin" ?
 *          "disaplay" : undefind]: "none"
 *    }}
 *  />
 * }
 * 
 */

const PrivateContentWrapper = props => {
	const {
		isAuthorized
	} = useAuthContext();
	const {
		rest,
		ChildPlaceholder
	} = useOverrides(props, overrides);
	/**
  * user && user.role === "admin" ? (
         <ChildPlaceholder
           slot={"auth-content"}
         />
       ) : <ChildPlaceholder slot="403-content"/>
  * 
  */

	return <Box {...rest} cells-number-total={2}>
		      
		{isAuthorized ? <ChildPlaceholder slot={"auth-content"} /> : <ChildPlaceholder slot="403-content" />}
		    
	</Box>;
};

export default Object.assign(PrivateContentWrapper, {
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "PrivateContentWrapper — my awesome component"
	}
});