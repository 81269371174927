import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Text, Strong, Button, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"width": "100wv",
	"min-width": "100wv",
	"position": "sticky",
	"top": "80px",
	"z-index": "4",
	"sm-padding": "0px 8px 0px 8px",
	"quarkly-title": "listingOnMain",
	"border-radius": "20px 20px 0px 0px",
	"height": "calc(100vh - 80px)",
	"padding": "30px 32px 30px 32px",
	"margin": "0 0 0px 0",
	"max-height": "calc(100vh - 80px)",
	"min-height": "calc(100vh - 80px)",
	"sm-height": "calc(100vh - 80px)"
};
const overrides = {
	"section": {
		"kind": "Section",
		"props": {
			"width": "100wv",
			"position": "sticky",
			"top": "90px",
			"z-index": "4",
			"sm-overflow-y": "visible",
			"sm-overflow-x": "visible",
			"quarkly-title": "case",
			"margin": "0 0 30px 0",
			"md-height": "80vh",
			"md-min-height": "80vh",
			"border-radius": "20px",
			"sm-border-radius": "15px",
			"sm-max-height": "none",
			"padding": "24px 0 0зч 0",
			"min-width": "100%",
			"sm-padding": "0 0 0px 0",
			"height": "100%",
			"min-height": "100%",
			"max-height": "100%",
			"sm-height": "100%",
			"sm-min-height": "100%"
		}
	},
	"sectionOverride": {
		"kind": "Override",
		"props": {
			"slot": "SectionContent",
			"width": "100%",
			"height": "100%",
			"min-height": "100%",
			"min-width": "100%",
			"justify-content": "flex-start",
			"background": "--color-light",
			"border-radius": "20px",
			"flex-direction": "row",
			"sm-flex-direction": "column",
			"md-flex-direction": "column",
			"sm-overflow-y": "hidden",
			"sm-margin": "0 0 0 0"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"width": "50%",
			"height": "100%",
			"src": "https://images.unsplash.com/photo-1627662168806-efa33a7cda86?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"object-fit": "cover",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"md-width": "100%",
			"md-height": "300px",
			"md-min-width": "100%",
			"md-min-height": "260px",
			"md-margin": "0px 0px 0px 0px",
			"sm-width": "100%",
			"quarkly-title": "photoOnMain1",
			"border-radius": "20px 0px 0px 20px",
			"sm-border-radius": "15px 14px 0px 0px",
			"md-border-radius": "20px 20px 0px 0px",
			"sm-min-height": "200px",
			"sm-height": "640px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"sm-flex-direction": "column",
			"sm-min-height": "max-content",
			"md-flex-direction": "column",
			"width": "50%",
			"sm-width": "100%",
			"sm-height": "100%",
			"sm-margin": "16px 0px 0px 0px",
			"sm-padding": "0px 8px 0px 8px",
			"min-height": "100%",
			"height": "100%",
			"margin": "0px 0px 0px 0px",
			"md-width": "100%",
			"padding": "20px 20px 20px 20px",
			"border-radius": "0px 20px 20px 0px",
			"sm-border-radius": "0px 0px 20px 20px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-font": "--h2Mob",
			"quarkly-title": "headLine",
			"margin": "20px 0px 46px 0px",
			"width": "100%",
			"height": "max-content",
			"min-height": "m",
			"sm-margin": "0px 0px 0px 0px",
			"md-margin": "40px 0px 2px 0px",
			"color": "inherit",
			"children": <>
				Интранет для Rolf{" "}
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"height": "max-content",
			"min-height": "max-content",
			"display": "flex",
			"flex-direction": "row",
			"sm-flex-direction": "column",
			"sm-height": "max-content",
			"sm-min-height": "max-content",
			"margin": "0px 0px 0px 0px",
			"md-margin": "20px 0px 0px 0px",
			"md-flex-direction": "column",
			"sm-margin": "0px 0px 0px 0px",
			"width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"min-width": "100%",
			"height": "max-content",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"sm-min-height": "max-content",
			"md-width": "100%",
			"md-min-width": "100%",
			"md-height": "max-content",
			"md-min-height": "max-content",
			"min-height": "max-content",
			"sm-margin": "12px 0px 0px 0px",
			"sm-height": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--headline3",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "10px 0 0px 0",
			"sm-font": "--headline3Mob",
			"quarkly-title": "task",
			"sm-margin": "0px 0 12px 0",
			"color": "inherit",
			"children": "О проекте"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "4px 0px 0px 0px",
			"height": "max-content",
			"min-height": "max-content",
			"width": "100%",
			"min-width": "100%",
			"sm-font": "--leadMob",
			"quarkly-title": "taskName",
			"sm-margin": "0px 0px 10px 0px",
			"color": "inherit",
			"children": "Внутренняя платформа для персонала крупнейшего в Европе автодиллера."
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"font": "--headline3",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-margin": "12px 0 0px 0",
			"margin": "20px 0 0px 0",
			"sm-font": "--headline3Mob",
			"quarkly-title": "solution",
			"color": "inherit",
			"children": "Функционал",
			"sm-display": "block"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-margin": "0 0 0px 0",
			"margin": "4px 0px 0px 0px",
			"sm-font": "--leadMob",
			"quarkly-title": "solutionNamr",
			"color": "inherit",
			"sm-display": "block",
			"children": "Внутренняя соц.сеть компании (просмотр новостей и историй, профили коллег), просмотр данных о своей зарплате, выполнении kpi. Возможность заказа справок, согласование отпуска"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"font": "--headline3",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-margin": "12px 0 0px 0",
			"margin": "20px 0 0px 0",
			"sm-font": "--headline3Mob",
			"quarkly-title": "solution",
			"color": "inherit",
			"children": "Решение",
			"sm-display": "block"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"sm-margin": "0 0 0px 0",
			"margin": "4px 0px 0px 0px",
			"sm-font": "--leadMob",
			"quarkly-title": "solutionNamr",
			"color": "inherit",
			"children": <>
				Бэкенд: Directual,{" "}
				<br />
				Фронт: Directual
			</>,
			"sm-display": "block"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-orange",
			"background": "rgba(0, 119, 204, 0)",
			"color": "--orange",
			"font": "--base",
			"margin": "30px 0px 0px 0px",
			"border-radius": "4px",
			"sm-margin": "12px 0px 0px 0px",
			"display": "none"
		}
	},
	"SectionContent": {
		"props": {
			"background": "--color-dark",
			"sm-padding": "0 0 0 0",
			"sm-margin": "0px 0 0px 0",
			"sm-height": "100%"
		}
	},
	"button2": {
		"props": {}
	}
};

const CaseProd = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			height="100%"
			min-height="100%"
			min-width="100%"
			justify-content="center"
			border-radius="20px"
			flex-direction="column"
		/>
		<Section {...override("section")}>
			<Override {...override("sectionOverride")} />
			<Image {...override("image")} />
			<Box {...override("box")}>
				<Text {...override("text")} />
				<Box {...override("box1")}>
					<Box {...override("box2")}>
						<Text {...override("text1")} />
						<Text {...override("text2")} />
						<Box {...override("box3")}>
							<Text {...override("text3")} />
							<Text {...override("text4")} />
							<Button {...override("button")}>
								Читать подробнее
							</Button>
						</Box>
						<Box {...override("box4")}>
							<Text {...override("text5")} />
							<Text {...override("text6")} />
							<Button {...override("button2")}>
								Читать подробнее
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		{children}
	</Section>;
};

Object.assign(CaseProd, { ...Section,
	defaultProps,
	overrides
});
export default CaseProd;