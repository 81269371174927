import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text } from "@quarkly/widgets";
const defaultProps = {
	"font": "normal 400 16px/1.5 --fontFamily-googleMontserrat",
	"sm-font": "--base",
	"md-font": "--text2",
	"margin": "0px 0px 0px 0px",
	"md-margin": "4px 0px 0px 0px",
	"height": "max-content",
	"min-height": "max-content",
	"quarkly-title": "docLink",
	"children": "Договор №65 от"
};
const overrides = {};

const DocLink = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Text {...rest}>
		{children}
	</Text>;
};

Object.assign(DocLink, { ...Text,
	defaultProps,
	overrides
});
export default DocLink;