import React, { createContext, useContext, useMemo } from 'react';
import atomize from "@quarkly/atomize";
import { Structure } from "@quarkly/widgets";
import Directual from "directual-api";
const apiContext = createContext();

const DirectualAPIProvider = ({
	children,
	appID,
	...otherProps
}) => {
	const api = useMemo(() => appID ? new Directual({
		appID,
		apiHost: "https://api.directual.com/"
	}) : undefined, [appID]);
	return <apiContext.Provider value={api}>
		      
		<Structure {...otherProps}>
			        
			{children}
			      
		</Structure>
		    
	</apiContext.Provider>;
};

export const useApiContext = () => useContext(apiContext);
const wrappedComponent = atomize(DirectualAPIProvider)({
	name: "DirectualAPIProvider",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "Provider for instance DirectualAPI class"
	},
	defaultProps: { ...Structure.defaultProps
	},
	propInfo: { ...Structure.propInfo,
		// paste here props description for your component
		appID: {
			translate: {
				en: "Application ID"
			},
			control: "input"
		}
	}
});
wrappedComponent.defaultProps = Structure.defaultProps;
export default wrappedComponent;