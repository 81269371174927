import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, Text, Box } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0px 20px 0px 0px",
	"display": "flex",
	"flex-direction": "column",
	"width": "120px",
	"align-items": "center",
	"min-width": "120зч",
	"quarkly-title": "person",
	"md-width": "120px",
	"md-min-width": "120px",
	"sm-min-width": "100px",
	"sm-margin": "0px 8px 0px 0px",
	"sm-height": "116px",
	"sm-width": "100px"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"width": "120px",
			"height": "120px",
			"src": "https://images.unsplash.com/photo-1581803118522-7b72a50f7e9f?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000",
			"object-fit": "cover",
			"border-radius": "10px",
			"quarkly-title": "photo",
			"min-width": "120px",
			"min-height": "120px",
			"md-width": "100px",
			"md-height": "100px",
			"md-min-width": "100px",
			"md-min-height": "100px",
			"sm-width": "80px",
			"sm-height": "80px",
			"sm-min-width": "80px",
			"sm-min-height": "80px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"width": "100%",
			"min-width": "100%",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"sm-font": "--baseMob",
			"sm-margin": "2px 0px 0px 0px",
			"md-font": "--baseMob",
			"quarkly-title": "name",
			"text-align": "center",
			"sm-height": "max-content",
			"sm-min-height": "max-content",
			"children": "Виктор",
			"font": "--base",
			"color": "--dark"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--text2",
			"color": "--dark",
			"margin": "0px 0px 0px 0px",
			"width": "100%",
			"min-width": "100%",
			"sm-width": "100%",
			"sm-min-width": "100%",
			"sm-font": "--baseMob",
			"sm-margin": "2px 0px 0px 0px",
			"md-font": "--baseMob",
			"quarkly-title": "position",
			"text-align": "center",
			"sm-height": "max-content",
			"sm-min-height": "max-content",
			"children": "программист"
		}
	}
};

const Person = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<Image {...override("image")} />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{children}
	</Box>;
};

Object.assign(Person, { ...Box,
	defaultProps,
	overrides
});
export default Person;